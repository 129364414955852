@import "../../Common/variables.module.scss";

$width: 210mm;

.sticker {
    width: 200mm;
    height: 15mm;
    position: relative;
}

.turnamentInfo {
    //transform: scale(0.6);
    //transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    width: 40mm;
    position: absolute;
    left: 0mm;
}

.container{
    padding: $padding;
}

.turnamentText {
    transform: scale(0.58);
    transform-origin: top left;
}

.tableContainer {
    width: 120mm;
    position: relative;
    left: 40mm;
}

.judgeContainer {
    position: absolute;
    left: 163mm;
    white-space: nowrap;
    overflow: hidden;
    width: 37mm;
}

.stickerTable {
    transform: scale(0.5);
    transform-origin: top left;
    white-space: nowrap;
    height: 12mm;
    width: 240mm !important;
}

.tableRow {
    line-height: 0.8 !important ;
}

.starterText {
    width: 240mm !important;
    transform: scale(0.5);
    transform-origin: top left;
    position: absolute;
    left: 3mm;
    top:11mm;
}

.a4Page {
    width: $width;
    height: 297mm;
    margin: 0;
    padding: 5mm;
    font-family: Arial, sans-serif;
}

.logo {
    width: 3.5cm;
}

.text {
    font-size: 12px;
    font-weight: bold;
}

* {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important; /* Firefox 48 – 96 */
    print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
}

.printInvisibleHeader {
    padding: $padding;
    margin: $padding;
    width: $width;
}

.stepStack{
    padding:$padding;
}

@media print {
    .printInvisibleHeader {
        display: none;
    }
}
