@import "../../Common/variables.module.scss";

.paper {
    padding: $padding;
}

.container {
    padding: $padding;
}

.buttonContainer {
    padding: $padding;
    width: 100%;
}
