@import "../../Common/variables.module.scss";

.participantTable {
    width: max-content;
}

.paper {
    padding: $padding;
}

.picker {
    width: 150px;
}

.participantPaper{
    padding: $padding;
}