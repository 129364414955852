@import "../../Common/variables.module.scss";

.runContainer {
    width: 100%;
    padding: $padding;
}

.timeContainer {
    padding: $padding;
}

.btn {
    width: 45%;
}

.runStats {
    width: 200px;
}

.runnerListEntryContainter {
    padding: $padding;
    height: fit-content;
}

.markedTable {
    background-color: lightgray;
}

.selected {
    background-color: lightsteelblue;
}

.button {
    width: 60px;
    height: 60px;
}

.timeBoxLeft {
    width: 80%;
    min-width: 300px;
}

.timeBoxRight {
    width: min-content;
}

.error {
    border: 2px $error-color solid;
    padding: $padding;
}

.buttonPaper {
    padding: $padding;
    height: min-content;
}

.starterTableRow {
    cursor: pointer;
}

.starterTableRow:hover {
    background-color: rgb(236, 236, 236);
}
