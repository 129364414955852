@import "../../Common/variables.module.scss";

.runStats {
    width: 120px;
}

.runTable {
    width: max-content;
}

.infoBox {
    padding: $padding;
    width: 250px;
}

.container {
    padding: $padding;
}

.padding {
    padding: $padding;
}

.dialogButtons {
    width: 100%;
    padding: $padding;
}
